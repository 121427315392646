<template>
  <v-container fluid>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-domain
      </v-icon> Companies List
    </h2>
    <br>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <v-col
        class="text-right"
      >
        <v-btn
          color="#FF3700"
          @click="showForm = true"
        >
          Add New
        </v-btn>
      </v-col>
      <hr>
      <br>
      <div
        v-if="!isCompaniesAvailable"
        class="no-file-alert"
      >
        <h4>
          No companies found.
        </h4>
      </div>
      <div>
        <v-expansion-panels
          elevation
          color="red"
          hover
          popout
          accordion
        >
          <v-expansion-panel
            v-for="(item,i) in companiesList"
            :key="i"
          >
            <v-expansion-panel-header class="my-name-style">
              {{ item.company_name }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="my-description-style">
              <span class="my-class">ABN Number: </span>{{ item.abn_number }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <div class="py-3" />
      <div>
        <create-company
          v-if="showForm"
          @closed="showForm = false"
          @company-created="getCompaniesList();"
        />
      </div>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import CreateCompany from 'src/views/dashboard/companies/CreateCompany';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  export default {
    name: 'CompaniesList',
    components: {
      'create-company': CreateCompany,
      'centre-spinner': spinner,
      },
    data () {
      return {
        showForm: false,
        loading: false,
      };
    },
    computed: {
      companiesList () {
        return this.$store.getters['companies/getCompaniesList'];
      },
      isCompaniesAvailable () {
        if (this.companiesList.length > 0) {
          return true;
        }
        return false;
      },
    },
    async mounted () {
     await this.getCompaniesList();
    },
    methods: {
      async getCompaniesList () {
        this.loading = true;
        await this.$store.dispatch('companies/fetchCompaniesList');
        this.loading = false;
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 20px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.my-name-style {
  font-size: 16px;
  font-weight: bold;
  color:#37474F;
}
.my-description-style {
  font-weight: bold;
  color:#FF3700 !important;
}
.v-expansion-panels {
  z-index: 0;
}
.my-class {
  color:#37474F !important;
}
.no-file-alert {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  margin-bottom: 50px;
  margin-top: 50px;
}
</style>
