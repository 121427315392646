<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
  >
    <v-card
      outlined
      color="#ECEFF1"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-pencil
        </v-icon>Create Company
      </h2>
      <p class="subheader-style">
        Add your service providing company
      </p>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-card-text class="text-body-1 text-center">
            <p class="my-note-style">
              NB:- If you got your staffs in service providers list, please add your organization as well from here.
            </p>
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col
                cols="12"
                md="10"
              >
                <v-text-field
                  v-model="name"
                  label="Company Name*"
                  dense
                  outlined
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="For your reference - 'DBSystems'"
                  persistent-hint
                />
              </v-col>
              <v-col
                cols="12"
                md="10"
              >
                <v-text-field
                  v-model="abnNumber"
                  dense
                  outlined
                  label="ABN Number*"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="For your reference - '12 345 678 901'"
                  persistent-hint
                />
                <br>
                <p class="text-sm-left">
                  *Indicates required field
                </p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              @click="showModal = false"
            >
              cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              @click="createCompany()"
            >
              create
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-dialog>
</template>
<script>
  import Constants from 'src/constants';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  export default {
    name: 'CreateCompany',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        showModal: true,
        name: '',
        abnNumber: '',
        loading: false,
        valid: false,
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
      createCompany () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please fill the required fields before proceeding.',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('companies/createCompany', {
          name: this.name,
          abn_number: this.abnNumber,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Comapny created successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$emit('company-created');
          this.loading = false;
          this.showModal = false;
        }).catch(() => {
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.doc-btn-style {
    background-color: #455a64 !important;
}
.subheader-style {
  color: #78909C;
  font-size: 18px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 0px;
}
.my-note-style {
  font-size: 14px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: bold;
  color: #FF3700;
  margin-bottom: 20px;
}
</style>
